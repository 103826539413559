import * as React from 'react';

type Props = {
  name: string;
};

const Icon: React.FC<Props> = (props) => {
  return <i className="icon material-symbols">{props.name}</i>;
};

export default Icon;
