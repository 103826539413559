import { SVGProps } from 'react';
import * as React from 'react';

export const ZendeskIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    {...props}
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12.25C0 8.38401 3.13401 5.25 7 5.25H21C24.866 5.25 28 8.38401 28 12.25C28 16.116 24.866 19.25 21 19.25H7C3.13401 19.25 0 16.116 0 12.25Z"
      fill="white"
    />
    <path d="M5.35953 21.7657L10.8693 21.7657L5.3602 27.2763L5.35953 21.7657Z" fill="white" />
  </svg>
);
