import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './styles/ZendeskDemoChat.scss';
import { ZendeskIcon } from './icons/ZendeskIcon';

export const ZendeskDemoChat: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="zendesk-demo-chat" data-rh={t('ZendeskDemoChat.tooltip')} data-rh-at="left">
      <ZendeskIcon />
    </div>
  );
};

export default ZendeskDemoChat;
