import { SVGProps } from 'react';
import * as React from 'react';

export const ErrorIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    {...props}
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_3203_6276"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="80"
      height="80">
      <rect width="80" height="80" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_3203_6276)">
      <path
        d="M39.9978 59.8289C40.723 59.8289 41.3022 59.613 41.7356 59.1811C42.1682 58.7493 42.3844 58.1711 42.3844 57.4467C42.3844 56.7215 42.1685 56.1422 41.7367 55.7089C41.3048 55.2763 40.7267 55.06 40.0022 55.06C39.277 55.06 38.6978 55.2759 38.2644 55.7078C37.8319 56.1396 37.6156 56.7178 37.6156 57.4422C37.6156 58.1674 37.8315 58.7467 38.2633 59.18C38.6952 59.6126 39.2733 59.8289 39.9978 59.8289ZM40.1844 45.4789C40.677 45.4789 41.0815 45.3152 41.3978 44.9878C41.7141 44.6611 41.8722 44.2548 41.8722 43.7689V19.8467C41.8722 19.3607 41.7063 18.9541 41.3744 18.6267C41.0433 18.3 40.6319 18.1367 40.14 18.1367C39.6482 18.1367 39.2441 18.3 38.9278 18.6267C38.6115 18.9541 38.4533 19.3607 38.4533 19.8467V43.7689C38.4533 44.2548 38.6189 44.6611 38.95 44.9878C39.2811 45.3152 39.6926 45.4789 40.1844 45.4789ZM40.0444 80C34.4756 80 29.2796 78.9504 24.4567 76.8511C19.633 74.7519 15.4015 71.8844 11.7622 68.2489C8.12296 64.6133 5.25296 60.3833 3.15222 55.5589C1.05074 50.7337 0 45.5337 0 39.9589C0 34.4411 1.04963 29.2533 3.14889 24.3956C5.24815 19.5385 8.11555 15.3089 11.7511 11.7067C15.3867 8.10444 19.6167 5.25296 24.4411 3.15222C29.2663 1.05074 34.4663 0 40.0411 0C45.5589 0 50.7467 1.04963 55.6045 3.14889C60.4615 5.24815 64.6911 8.09704 68.2933 11.6956C71.8956 15.2941 74.747 19.5259 76.8478 24.3911C78.9493 29.2556 80 34.4437 80 39.9556C80 45.5244 78.9504 50.7204 76.8511 55.5433C74.7519 60.367 71.903 64.5919 68.3044 68.2178C64.7059 71.843 60.4741 74.713 55.6089 76.8278C50.7444 78.9426 45.5563 80 40.0444 80ZM40.0556 76.5811C50.1948 76.5811 58.8174 73.0241 65.9233 65.91C73.0285 58.7959 76.5811 50.1407 76.5811 39.9444C76.5811 29.8052 73.0356 21.1826 65.9445 14.0767C58.8526 6.97148 50.2044 3.41889 40 3.41889C29.8407 3.41889 21.2041 6.96445 14.09 14.0556C6.97593 21.1474 3.41889 29.7956 3.41889 40C3.41889 50.1593 6.97593 58.7959 14.09 65.91C21.2041 73.0241 29.8593 76.5811 40.0556 76.5811Z"
        fill="#BC1D3B"
      />
    </g>
  </svg>
);
