import { SVGProps } from 'react';
import * as React from 'react';

export const CheckCircle = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_2478_8393"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="80"
      height="80">
      <rect width="80" height="80" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2478_8393)">
      <path
        d="M33.4444 52.0769L21.7863 40.3333C21.4046 40.0085 20.9744 39.8319 20.4958 39.8034C20.0171 39.7749 19.5783 39.9601 19.1794 40.359C18.7977 40.7408 18.6068 41.171 18.6068 41.6496C18.6068 42.1282 18.7977 42.5584 19.1794 42.9402L31.282 55.0685C31.8917 55.7351 32.6125 56.0685 33.4444 56.0685C34.2764 56.0685 34.9972 55.7351 35.6069 55.0685L60.6239 30.1368C60.9658 29.7379 61.151 29.2992 61.1794 28.8206C61.208 28.3419 61.0228 27.9031 60.6239 27.5042C60.2251 27.1225 59.7821 26.9359 59.2949 26.9444C58.8077 26.953 58.3732 27.1481 57.9914 27.5299L33.4444 52.0769ZM40.0149 80C34.5007 80 29.3231 78.9504 24.4822 76.8512C19.6413 74.752 15.4012 71.8845 11.7621 68.2488C8.12307 64.6131 5.25296 60.377 3.15178 55.5407C1.05059 50.7043 0 45.529 0 40.0149C0 34.4836 1.04959 29.2832 3.14878 24.4138C5.24804 19.5444 8.11552 15.3086 11.7512 11.7066C15.3869 8.10456 19.623 5.25296 24.4593 3.15178C29.2957 1.05059 34.471 0 39.9851 0C45.5164 0 50.7168 1.04959 55.5862 3.14878C60.4556 5.24804 64.6914 8.097 68.2934 11.6957C71.8954 15.2943 74.747 19.5261 76.8482 24.3909C78.9494 29.2558 80 34.4539 80 39.9851C80 45.4993 78.9504 50.6769 76.8512 55.5178C74.752 60.3587 71.903 64.5988 68.3043 68.2379C64.7057 71.8769 60.4739 74.747 55.6091 76.8482C50.7442 78.9494 45.5462 80 40.0149 80ZM40 76.5812C50.1767 76.5812 58.8177 73.0242 65.9231 65.9102C73.0285 58.7963 76.5812 50.1596 76.5812 40C76.5812 29.8233 73.0285 21.1823 65.9231 14.0769C58.8177 6.97148 50.1767 3.41878 40 3.41878C29.8404 3.41878 21.2037 6.97148 14.0898 14.0769C6.97578 21.1823 3.41878 29.8233 3.41878 40C3.41878 50.1596 6.97578 58.7963 14.0898 65.9102C21.2037 73.0242 29.8404 76.5812 40 76.5812Z"
        fill="url(#paint0_linear_2478_8393)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2478_8393"
        x1="80"
        y1="4.70877e-06"
        x2="0.999998"
        y2="78.5"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#036297" />
        <stop offset="1" stopColor="#0172B1" />
      </linearGradient>
    </defs>
  </svg>
);
