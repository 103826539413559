import * as React from 'react';

// Utils
// Constants
// Actions, Models & Interfaces
// Components
// Styles
import './styles/LoaderOverlay.scss';

type Props = {
  isOpen: boolean;
  icon?: React.ReactNode;
  title?: string;
  body?: any;
  buttonText?: string;
  showButton?: boolean;
  onButtonClicked?: () => void;
};

const InformationOverlay: React.FC<Props> = ({
  isOpen,
  icon,
  title,
  body,
  buttonText,
  showButton,
  onButtonClicked,
}) => {
  const [isOverlayOpen, setIsOverlayOpen] = React.useState(isOpen);

  const handleButtonClick = () => {
    if (onButtonClicked) {
      onButtonClicked();
    } else {
      setIsOverlayOpen(false);
    }
  };

  if (!isOverlayOpen) {
    return null;
  }

  return (
    <div className={`tcp-loader-overlay`}>
      <div className="tcp-loader-overlay-content">
        <div className="tcp-loader-overlay-inner">
          {icon && <div className="tcp-loader-overlay-icon">{icon}</div>}
          {title && <h2 className="tcp-loader-overlay-content-title">{title}</h2>}
          <div className="tcp-loader-overlay-text">{body}</div>
          {showButton && (
            <button className="tcp-loader-overlay-button button" onClick={handleButtonClick}>
              {buttonText || ''}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default InformationOverlay;
