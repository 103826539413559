import * as React from 'react';

// Utils
// Constants
// Actions
// Models & Interfaces
// Components
// Styles
import './styles/ScrollToTop.scss';
import Icon from './Icon';

export const ScrollToTop: React.FC = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="lp-scroll-up-btn" onClick={scrollToTop}>
      <Icon name="arrow_upward" />
    </div>
  );
};
