import * as React from 'react';
import classNames from 'classnames';

type Props = {
  label: string;
  isActive?: boolean;
  activeClassName?: string;
  onClick?: () => void;
};

const BreadcrumbItem: React.FC<Props> = (props) => {
  return (
    <li
      className={classNames('breadcrumbs-item', {
        'is--active': props.isActive,
        'is--clickable': props.onClick,
      })}
      onClick={props.onClick}>
      <span className="breadcrumbs-label">{props.label}</span>
    </li>
  );
};

export default BreadcrumbItem;
