import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  basketStatus,
  isAnyBookingItemAirbnb,
  isBookingConfirmFinished,
} from '@src/shared/src/selectors/checkoutSelectors';
import { t } from '@toolkit/util/i18n';
import { InformationOverlay } from '@toolkit/ui';
import { STATUS } from '@src/shared/src/const/app';
import { CheckCircle } from '@toolkit/ui/icons/CheckCircle';

type Props = {
  isJourney: boolean;
};

export const CheckoutOverlayMessages: React.FC<Props> = (props) => {
  const bookingHasAirbnb = useSelector(isAnyBookingItemAirbnb);
  const bookingConfirmFinished = useSelector(isBookingConfirmFinished);
  const status = useSelector(basketStatus) as STATUS;

  const confirmOverlayTextBody =
    bookingHasAirbnb && bookingConfirmFinished
      ? t('checkout-overlay-messages.text.airbnb-booking')
      : t('informationOverlay.body.bookingSuccess');

  return (
    <InformationOverlay
      key={`info-overlay-confirm-${status === STATUS.CONFIRM_FINISHED}`}
      isOpen={status === STATUS.CONFIRM_FINISHED && !props.isJourney}
      icon={<CheckCircle />}
      title={
        bookingHasAirbnb
          ? t('checkout-overlay-messages.title.congratulation-airbnb')
          : t('informationOverlay.title.congratulation')
      }
      body={confirmOverlayTextBody}
      showButton={true}
      buttonText={t('informationOverlay.button.proceedConfirmation')}
    />
  );
};
