import { SVGProps } from 'react';
import * as React from 'react';

export const RestartIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_2478_9479"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="80"
      height="80">
      <rect width="80" height="80" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2478_9479)">
      <path
        d="M34.6146 75.8221C27.1905 74.4845 21.0652 70.9475 16.2386 65.211C11.4129 59.4746 9 52.7652 9 45.0828C9 40.8169 9.87558 36.7346 11.6267 32.8359C13.3779 28.9372 15.8471 25.5177 19.0345 22.5775C19.2867 22.3021 19.6226 22.183 20.0422 22.2203C20.4609 22.2577 20.8252 22.4312 21.1349 22.741C21.5373 23.0858 21.7153 23.5135 21.669 24.0241C21.6235 24.5354 21.3824 24.9635 20.9457 25.3084C18.1555 27.8634 16.0412 30.8679 14.6028 34.3217C13.1644 37.7747 12.4452 41.3618 12.4452 45.0828C12.4452 51.812 14.567 57.7134 18.8105 62.787C23.0533 67.8598 28.4389 71.0564 34.9673 72.3769C35.3868 72.4112 35.7328 72.6064 36.0052 72.9625C36.2784 73.3185 36.415 73.7063 36.415 74.1258C36.415 74.6536 36.2284 75.0768 35.8552 75.3955C35.482 75.7143 35.0684 75.8565 34.6146 75.8221ZM46.2244 75.9945C45.7139 76.0289 45.272 75.9009 44.8987 75.6105C44.5255 75.3209 44.3389 74.9118 44.3389 74.3833C44.3389 73.9071 44.4751 73.491 44.7476 73.1349C45.02 72.7789 45.3948 72.5549 45.8717 72.4631C52.3598 70.9128 57.7171 67.6299 61.9434 62.6145C66.169 57.5992 68.2819 51.7553 68.2819 45.0828C68.2819 37.2914 65.579 30.6925 60.1732 25.286C54.7675 19.8802 48.1685 17.1774 40.3764 17.1774H36.6726L43.451 23.9558C43.819 24.3231 44.003 24.7336 44.003 25.1874C44.003 25.6413 43.819 26.0518 43.451 26.4191C43.0666 26.8035 42.6475 26.9957 42.1936 26.9957C41.7398 26.9957 41.3293 26.8035 40.962 26.4191L32.1513 17.6342C31.8356 17.3184 31.6031 16.9881 31.4538 16.6433C31.3045 16.2992 31.2299 15.9032 31.2299 15.4553C31.2299 15.0642 31.3045 14.682 31.4538 14.3088C31.6031 13.9355 31.8356 13.5911 32.1513 13.2753L40.962 4.46466C41.3293 4.15489 41.7398 4 42.1936 4C42.6475 4 43.0666 4.15489 43.451 4.46466C43.819 4.84983 44.003 5.28351 44.003 5.76572C44.003 6.24792 43.819 6.64391 43.451 6.95368L36.6726 13.7321H40.3764C49.1158 13.7321 56.5257 16.7911 62.6063 22.9089C68.6868 29.0268 71.7271 36.4181 71.7271 45.0828C71.7271 52.7077 69.3183 59.3884 64.5007 65.1248C59.6832 70.8613 53.5911 74.4845 46.2244 75.9945Z"
        fill="#BC1D3B"
      />
    </g>
  </svg>
);
