import * as React from 'react';

// Utils
import { tripHooks } from '@src/services';
import { useTranslation } from 'react-i18next';
// Constants
import { DIRECTION } from '@src/shared/src/const/app';
import { SearchModel } from '@src/shared/src/models';
import { isMultiCitySearch } from '@src/shared/src/util/search';
// Actions
// Models
// Interfaces

type Props = {
  search: SearchModel;
  direction: DIRECTION;
};

export const TripHeader: React.FC<Props> = ({ search, direction }) => {
  const { t } = useTranslation();
  const filteredTripsCount = tripHooks.useFilteredTripsCount(direction);

  return (
    <div className="content-title">
      {`${String(filteredTripsCount)} ${
        isMultiCitySearch(search)
          ? t('tripsConn.trips.header.route', {
              count: direction === DIRECTION.OUTWARD ? 1 : 2,
            })
          : t(`tripsConn.trips.header.${direction}`)
      }`}
    </div>
  );
};

export default TripHeader;
