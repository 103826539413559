import * as React from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import Select from 'react-select';

// Utils
import { t } from '@toolkit/util/i18n';
import { cond, equals, any as anyElement } from '@src/shared/src/util/general';
import { navigate, baseErrorCond, notifyError } from '@toolkit/util/app';
import i18n from '@src/toolkit/util/i18n';
import { tripSelectors, searchSelectors, checkout } from '@src/shared/src/selectors';
import { AppContextProp, withAppContext } from '@toolkit/util/AppContext';
import { errorLogger } from '@src/shared/src/util/errors';
import { shouldSearchForHotels, isRoundtripSearch } from '@src/shared/src/util/search';
// Constants
import {
  DIRECTION,
  ERRORS,
  TRANSPORT_SORT_VALUES,
} from '@src/shared/src/const/app';
import { OVERLAY_TIMEOUT } from '@src/shared/src/const/app';
// Actions, Models & Interfaces
import { tripsActions, filterActions } from '@src/shared/src/actions';
import { setUiMobileFilterOverlay, setUiMobileNoScrolling } from '@pod/ui/actions';
import { uiMobileFilterOverlay, uiMobileNoScrolling } from '@pod/ui/selectors';

import {
  OptionModel,
  TravelSearchAggregatorModel,
  SearchModel,
  TripModel,
  RedeemingAllowanceModel,
} from '@src/shared/src/models';
import { IRootState, ConnectedRedux } from '@src/store';
import { selectors } from '@src/shared/src';

// Components
import { TripSideBarConn } from '@pod/trips';
import {
  AggregatorWarnings,
  BookedTrip,
  TripHeader,
  TripNoResults,
  TripsList,
  SelectedTrip,
  TripsMobileNavbar,
} from '@pod/trips/components';
import {
  LoaderOverlay,
  LoaderOverlayBody,
  InformationOverlay,
  BENotificationBar,
  AllowanceSelectionNotification,
  RebookingBanner,
  SearchExpiredBanner,
} from '@toolkit/ui';
import { RedeemedSearchNotification } from '@pod/checkout/components';
import LoaderResults from '../components/LoaderResults';
import SortBar from '../components/SortBar';
import { ErrorIcon } from '@toolkit/ui/icons/ErrorIcon';
// Styles

type Props = ConnectedRedux<IRootState> & AppContextProp & {
  filteredTripsCount:number;
  isBasketEditable:boolean;
  tripSortingFilter:string;
  searchId:number;
  search:SearchModel;
  isSearchExpired:boolean;
  direction:DIRECTION;
  transportWarnings:TravelSearchAggregatorModel[];
  uiMobileFilterOverlay:boolean;
  uiMobileNoScrolling:boolean;
  trips:TripModel[];
  redeemingAllowance: RedeemingAllowanceModel;
};
const initialState = {
  currentMaxTrips: 10,
  isTripsLoading: false,
  showFailedOverlay: false,
  showSearchLoadingOverlay: false,
  showTripProcessingOverlay: false,
  isBasketLocked: false,
  isSearchDelayed: false,
};
type State = Readonly<typeof initialState>;

class TripsConn extends React.PureComponent<Props, State> {
  readonly state:State = initialState;

  private SearchLoadingOverlayInterval: any;


  public componentWillMount() {
    this.setState({
      isTripsLoading: true,
      showSearchLoadingOverlay: true,
      showTripProcessingOverlay: false,
    });
    this.loadTrips(this.props.searchId, this.props.direction);

    this.SearchLoadingOverlayInterval = setTimeout(() => {
      if (this.state.isTripsLoading) {
        this.setState({ 
          showSearchLoadingOverlay: true, 
          isSearchDelayed: true
        });
      }
    }, OVERLAY_TIMEOUT.DELAYED_SEARCH);
  }

  public componentWillReceiveProps(nextProps:Props) {
    if (nextProps.direction !== this.props.direction) {
      this.setState({
        isTripsLoading: true,
        showSearchLoadingOverlay: false,
        showTripProcessingOverlay: true,
      });
      this.loadTrips(this.props.searchId, nextProps.direction);
      this.setState({ currentMaxTrips: 10});
    }

    this.setState({ isBasketLocked: !nextProps.isBasketEditable});

    // Usually we hide the overlay as soon as we get trips
    // But when the search is delayed we should keep it visible until search is finished
    if (
      this.state.showSearchLoadingOverlay &&
      this.props.filteredTripsCount > 0 &&
      !this.state.isSearchDelayed
    ) {
      this.setState({ showSearchLoadingOverlay: false});
    }
  }

  public componentWillUnmount() {
    clearTimeout(this.SearchLoadingOverlayInterval);
  }

  private loadTrips = (searchId:number, direction:string) => {
    if (searchId && direction) {
      this.props.dispatch(tripsActions.fetchTripsAsync.request(
        {
          onSuccess: () => {
            this.setState({
              isTripsLoading: false,
              showSearchLoadingOverlay: false,
              showTripProcessingOverlay: false,
            });

            const tripPrices: number[] = this.props.trips.map((trip) => trip.baseTotal);
            if (anyElement(equals(0))(tripPrices)) {
              errorLogger('Trip baseTotal 0', new Error('At least one trip has a baseTotal equal to 0'));
            }
          },
          onError: cond([
            [equals(ERRORS.FAILED), () =>
              this.setState({
                isTripsLoading: false,
                showSearchLoadingOverlay: false,
                showTripProcessingOverlay: false,
                showFailedOverlay: true,
              }),
            ],
            [equals(ERRORS.DETAILS_FAILED), () =>
              notifyError(ERRORS.DETAILS_FAILED, i18n.t('tripsConn.notification.detailsFailed'))
            ],
            ...baseErrorCond,
          ]),
          searchId,
          direction,
        }
      ));
    }
  }

  private changeShuttleType = (vehicle:string, depOrArr:string) => {
    this.setState({ isTripsLoading: true });
    this.props.dispatch(tripsActions.changeShuttleTypeAsync.request({
      onError: cond(baseErrorCond),
      direction: this.props.direction,
      vehicle,
      depOrArr,
    }));
  }

  private sortingOptions = ():OptionModel[] => [
    { label: t('TripsConn.select.option.priceSort'), value: TRANSPORT_SORT_VALUES.PRICE},
    { label: t('TripsConn.select.option.durationSort'), value: TRANSPORT_SORT_VALUES.DURATION},
    { label: t('TripsConn.select.option.depTimeSort'), value: TRANSPORT_SORT_VALUES.DEP_AT},
    { label: t('TripsConn.select.option.arrTimeSort'), value: TRANSPORT_SORT_VALUES.ARR_AT},
  ]

  private toggleMobileFilterOverlay = () =>  {
    if (this.props.uiMobileFilterOverlay) {
      this.props.dispatch(setUiMobileFilterOverlay(false));
      this.props.dispatch(setUiMobileNoScrolling(false));
    } else {
      this.props.dispatch(setUiMobileFilterOverlay(true));
      this.props.dispatch(setUiMobileNoScrolling(true));
    }
  }

  public render() {
    const {
      direction,
      isSearchExpired,
      transportWarnings,
      appContext,
      uiMobileFilterOverlay,
      uiMobileNoScrolling,
      search,
      redeemingAllowance
    } = this.props;
    return (
      <React.Fragment>
        <div className={`main-container ${uiMobileNoScrolling ? 'no-scrolling' : ''}`}>
          <BENotificationBar currentPage="TransportSearch"/>
        
          {this.state.showSearchLoadingOverlay &&
            <LoaderOverlay
              showSpinner={false}
              showInBox={true}
              body={
                <LoaderOverlayBody
                  title={t('loaderOverlay.title.pleaseHoldOn')}
                  delayedTitle={t('loaderOverlay.subTitle.delayed')}
                  isSearchDelayed={this.state.isSearchDelayed}
                  subTitle={
                    <Trans i18nKey="loaderOverlay.subTitle.searchingForBestOptions">
                      <br/>
                    </Trans>}
                  showHotel={shouldSearchForHotels(this.props.search)}/>
                }
            />
          }
          {this.state.showTripProcessingOverlay &&
            <LoaderOverlay showSpinner={true} title={t('global.processingTrip')} />}
          <TripsMobileNavbar search={search} direction={direction} />
          <div className="row">
            <TripSideBarConn
              isTripsLoading={this.state.isTripsLoading}
              onChangeShuttleType={this.changeShuttleType}
              direction={direction}
              isOpen={uiMobileFilterOverlay}/>
            <section className="content content-trip">
              <SearchExpiredBanner isSearchExpired={isSearchExpired}/>
              <RedeemedSearchNotification redeemingAllowance={redeemingAllowance} />
              <AllowanceSelectionNotification
                redeemingAllowance={redeemingAllowance}
                selection="transport"
                direction={this.props.direction}
              />
              <RebookingBanner />
              <AggregatorWarnings searchWarnings={transportWarnings} />
              <div className="content-wrapper">
                <SelectedTrip direction={direction as DIRECTION}/>
                {!this.props.isBasketEditable &&
                  <BookedTrip
                    direction={(direction as DIRECTION)}
                    isRT={isRoundtripSearch(this.props.search)} />
                }
                {this.state.isTripsLoading ? 
                  <LoaderResults finished={!this.state.isTripsLoading} /> 
                  : <div className="content-header">
                      <TripHeader direction={direction} search={search} />
                      {!appContext.isMediaBPNotMobile ?
                        <SortBar
                          options={this.sortingOptions()}
                          value={this.props.tripSortingFilter}
                          onChange={(nOpt) => this.props.dispatch(filterActions.setTripSortingFilter({
                            constraint: { current: nOpt.value},
                            direction,
                            onlyUpdateConstraint: false,
                          }))}
                        /> :
                        <div className="content-sort">
                          {t('tripsConn.sort.label')}:
                          <Select
                            className="Select--trips"
                            options={this.sortingOptions()}
                            clearable={false}
                            backspaceRemoves={false}
                            deleteRemoves={false}
                            value={this.props.tripSortingFilter}
                            onChange={(nOpt) => this.props.dispatch(filterActions.setTripSortingFilter({
                              constraint: { current: nOpt.value},
                              direction,
                              onlyUpdateConstraint: false,
                            }))}
                          />
                        </div>
                      }
                    </div>
                }                  
                <TripNoResults isTripsLoading={this.state.isTripsLoading} direction={direction}/>
                <TripsList
                  trips={this.props.trips}
                  direction={(direction as DIRECTION)}
                  isRT={isRoundtripSearch(this.props.search)}
                  isTripsLoading={this.state.isTripsLoading} />
                  <div className="content-filter-buttons">
                    <button className="button" onClick={() => this.toggleMobileFilterOverlay()}>
                      <i className="icon-filter_list"></i>
                      {uiMobileFilterOverlay ? t('tripsConn.button.close') : t('tripsConn.button.filter') }
                    </button>
                  </div>
              </div>
            </section>
            <div>
            <InformationOverlay
              key={`trips-failed-overlay-${this.state.showFailedOverlay}`}
              isOpen={this.state.showFailedOverlay}
              icon={<ErrorIcon />}
              title={t('informationOverlay.title.searchFailed')}
              body={t('informationOverlay.body.searchFailed')}
              showButton={true}
              buttonText={t('informationOverlay.button.restartSearch')}
              onButtonClicked={() => navigate('/')}/>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state:IRootState, props:any) => ({
  filteredTripsCount: props.direction === DIRECTION.OUTWARD
    ? tripSelectors.outwardFilteredTripsCount(state.trips) : tripSelectors.inboundFilteredTripsCount(state.trips),
  trips: props.direction === DIRECTION.OUTWARD
    ? tripSelectors.outwardFilteredTrips(state.trips) : tripSelectors.inboundFilteredTrips(state.trips),
  isBasketEditable: checkout.isBasketEditable(state.checkout),
  tripSortingFilter: state.filters.tripSortingFilter.current,
  searchId: searchSelectors.searchId(state.search),
  search: selectors.search.search(state.search),
  isSearchExpired: searchSelectors.isSearchExpired(state.search),
  transportWarnings: searchSelectors.transportWarnings(state),
  uiMobileFilterOverlay: uiMobileFilterOverlay(state),
  uiMobileNoScrolling: uiMobileNoScrolling(state),
  redeemingAllowance: state.checkout.redeemingAllowance,
});
export default connect(mapStateToProps)(withAppContext(TripsConn));
