import * as React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

// Utils
import { formatDate, formatDateRange } from '@src/shared/src/util/date';
import { isHotelOnlySearch, isRentalOnlySearch, isRoundtripSearch } from '@src/shared/src/util/search';
// Constants
// Actions, Models & Interfaces
import { CoordinatesModel, SearchModel } from '@src/shared/src/models';
// Components
import { MapMarker } from '@pod/search/components';
// Styles

type Props = {
  search: SearchModel;
};

type State = {
  googleMap: google.maps.Map;
  currentZoomValue: number;
};

const customMapStyle: google.maps.MapTypeStyle[] = [
  {
    featureType: 'all',
    elementType: 'all',
    stylers: [{ lightness: '0' }],
  },
  {
    featureType: 'all',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'all',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#444444' }],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry',
    stylers: [{ visibility: 'on' }, { color: '#fefefe' }, { weight: '0.90' }],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative.province',
    elementType: 'geometry.stroke',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'all',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'geometry.stroke',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [{ gamma: '1' }, { weight: '1' }, { color: '#E5EDF5' }, { visibility: 'on' }],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      { saturation: -100 },
      { lightness: '100' },
      { visibility: 'off' },
      { color: '#eeefef' },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [{ visibility: 'simplified' }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [{ color: '#F4F7F9' }, { visibility: 'on' }],
  },
];

const mapOptions = {
  styles: customMapStyle,
  minZoom: 3,
  maxZoom: 8,
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: false,
};
class SearchMap extends React.Component<Props, State> {
  public state: State = {
    googleMap: null,
    currentZoomValue: 6,
  };
  setCurrentZoomValue = () => {
    if (this.state.googleMap) this.setState({ currentZoomValue: this.state.googleMap.getZoom() });
  };

  getCurvedPolyline = (depLat: number, depLng: number, arrLat: number, arrLng: number) => {
    const depCoord = new CoordinatesModel(depLat, depLng);
    const arrCoord = new CoordinatesModel(arrLat, arrLng);

    if (this.state.googleMap?.getProjection() && depCoord.exists && arrCoord.exists) {
      const pos1 = new google.maps.LatLng(depCoord.lat, depCoord.lng);
      const pos2 = new google.maps.LatLng(arrCoord.lat, arrCoord.lng);
      const p1 = this.state.googleMap.getProjection().fromLatLngToPoint(pos1);
      const p2 = this.state.googleMap.getProjection().fromLatLngToPoint(pos2);
      const e = new google.maps.Point(p2.x - p1.x, p2.y - p1.y);
      const m = new google.maps.Point(e.x / 2, e.y / 2);
      const o = new google.maps.Point(-e.y, e.x);
      const c = new google.maps.Point(m.x + 0.25 * o.x, m.y + 0.25 * o.y);
      const pathDef = 'M 0,0 ' + 'q ' + c.x + ',' + c.y + ' ' + e.x + ',' + e.y;
      const scale = 1 / Math.pow(2, -this.state.currentZoomValue);
      const icon = {
        path: pathDef,
        scale,
        strokeWeight: 2,
        strokeColor: '#D6DEE6',
        fillColor: 'none',
      };
      return <Marker position={depCoord} icon={icon} />;
    }
  };

  shouldShowArrivalMarker = (sectionIndex: number) => {
    const { search } = this.props;
    const isLastSection = sectionIndex === search.sections.length - 1;
    // show arrival marker if it's the last section
    if (isLastSection) return true;

    // show arrival marker if the next section's departure location is different from the current section's arrival location
    if (search.sections[sectionIndex + 1].depName !== search.sections[sectionIndex].arrName)
      return true;

    return false;
  };

  renderMapMarker = (lat: number, lng: number, name: string, date?: string) => {
    const coord = new CoordinatesModel(lat, lng);
    if (coord.exists) {
      return <MapMarker position={coord} locationLabel={name} dateLabel={date} />;
    }
  };

  renderMapElement = () => {
    const { search } = this.props;

    if (isHotelOnlySearch(search)) {
      return this.renderMapMarker(
        search.arrLat,
        search.arrLng,
        search.arrName,
        search.depAt && search.arrAt && formatDateRange(search.depAt, search.arrAt),
      );
    }

    if (isRentalOnlySearch(search)) {
      return (
        <>
          {this.renderMapMarker(
            search.depLat,
            search.depLng,
            search.depName,
            search.depAt && formatDate(search.depAt),
          )}
          {this.renderMapMarker(
            search.arrLat,
            search.arrLng,
            search.arrName,
            search.arrAt && formatDate(search.arrAt),
          )}
          {this.getCurvedPolyline(search.depLat, search.depLng, search.arrLat, search.arrLng)}
        </>
      );
    }

    return search.sections?.map((section, index) => (
      <span key={`section-${index}-markers`}>
        {this.renderMapMarker(
          section.depLat,
          section.depLng,
          section.depName,
          section.depAt && formatDate(section.depAt),
        )}
        {isRoundtripSearch(search) && index === 1 ? null : (
          <>
            {this.shouldShowArrivalMarker(index) &&
              this.renderMapMarker(section.arrLat, section.arrLng, section.arrName)}
            {this.getCurvedPolyline(section.depLat, section.depLng, section.arrLat, section.arrLng)}
          </>
        )}
      </span>
    ));
  };

  public render() {
    return (
      <GoogleMap
        zoom={this.state.currentZoomValue}
        center={{ lat: 51, lng: 8 }}
        options={mapOptions}
        onLoad={(googleMap) => this.setState({ googleMap })}
        onUnmount={() => this.setState({ googleMap: null })}
        onZoomChanged={this.setCurrentZoomValue}
        onProjectionChanged={this.setCurrentZoomValue}>
        {this.renderMapElement()}
      </GoogleMap>
    );
  }
}

export default SearchMap;
