import * as React from 'react';
import { SidebarSection, TextField } from '@toolkit/ui';

export interface IProps {
  title: string;
  currentValue: string;
  onChange: (value: string) => void;
}

export interface IState {
  currentValue: string;
}

export default class InputFilter extends React.Component<IProps, IState> {
  public state: IState = {
    currentValue: this.props.currentValue,
  };

  private onChangeInput = (value: string) => {
    this.setState({
      currentValue: value,
    });
    this.props.onChange(value);
  };

  public render() {
    return (
      <SidebarSection title={this.props.title} isOpen={true}>
        <TextField value={this.state.currentValue} onChange={this.onChangeInput} size="small" />
      </SidebarSection>
    );
  }
}
